<template>
  <div style="background-color: #fff;padding: 20px;">
    <div class="notice-title h1">{{ detailData.buildingName }}</div>
    <div class="h4 time">【时间】：{{detailData.createTime}}</div>
    <div class="notice-content">
      <img :src="detailData.pictureUrl" class="image" />
      <div class="right">
        <div class="row">
          <div style="color: #FC6035;" class="h1">{{ detailData.totalPrice }}</div>
          <div>元</div>
          <div style="margin-left: 20px;">{{detailData.unitPrice}}/m²</div>
        </div>
        <div class="row" style="margin-bottom: 20px;">
          <div class="unit-item value-title h1">{{detailDatabuildArea}}m²</div>
          <div class="unit-item value-title h1">{{ detailData.workersNum }}</div>
          <div class="unit-item value-title h1">{{ detailData.renovation=='1'?'毛坯':'精装' }}</div>
        </div>
        <div class="row">
          <div class="unit-item">建筑面积</div>
          <div class="unit-item">推荐工位数</div>
          <div class="unit-item">装修</div>
        </div>
        <div class="row">楼盘位置： {{ detailData.buildingAddress }}</div>
        <div class="row">联 系 人：{{ detailData.contactName }}</div>
        <div class="row">联系电话：{{ detailData.contactPhone }}</div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      notice_id: null,
      detailData: {},
    }
  },
  computed: {
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const notice_id = this.$route.query?.id
    if (notice_id) {
      this.notice_id = notice_id
      this.$api.solicitationCloud.businessBuildingDetail({ 'id': this.notice_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.notice-title {
  text-align: center;
  margin-top: 35px;
  font-weight: 700;
}
.time {
  text-align: center;
  margin: 10px 0;
  padding-bottom: 30px;
  border-bottom: 2px dashed #cccccc;
}
.notice-content {
  margin-top: 30px;
  height: 600px;
  display: flex;
  align-items: stretch;
  .image {
    flex: 1;
    min-width: 300px;
    height: 100%;
  }
  .right {
    flex: 1;
    min-width: 300px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    .row {
      display: flex;
      align-items: flex-end;
      margin-bottom: 60px;
      .unit-item {
        width: 100px;
      }
      .value-title {
        font-weight: 500;
      }
    }
  }
}
</style>
